export default {
    message: {
      ConnectWallet: '连接钱包',
      ConnectTip: '请连接钱包',
      Mobile: '移动',
      ChooseWallet: '选择你的钱包',
      ChianTypeTip: '请切换到币安链操作',
      Tips: '提示',
      LogOutTip: '确认要退出登录吗？',
      Ok: '确定',
      Cancel: '取消',
      WaitTip: '请刷新页面或稍后再试！',
      BuySuc: '认购成功',
      BuyErr: '认购失败',
      Share: '份',
      Subscribe: '立即加入',
      Piece: '张',
      Advantage: 'DMD优势',
      AdvantageTip1: '1、矩阵规则，公平公正，收益直观',
      AdvantageTip2: '2、一次投入，持续收入，长入稳定',
      AdvantageTip3: '3、简化“倍数”出局制，无过多层级算法奖励套路',
      AdvantageTip4: '4、95%以上的拔出率，只为生态赋能而生',
      // AdvantageTip4: '4、扶底保险池，没有重启之忧',
      AdvantageTip5: '5、首创分布式团队循环收益，长期管道收入',
      AdvantageTip6: '6、上帮下扶，左右填补，加速收益',
      Partner: '合作伙伴',
      CopySuc: '复制成功',
      CopyErr: '复制失败',
      MatrixDesc: 'DMD规则',
      ShareAward: '邀请新用户获得丰厚奖励',
      ShareLink: '您的专属邀请链接',
      CopyLink: '复制邀请链接',
      NoBlind: '未完成开启盲盒的任务值',
      Desc1: '每个参与玩家充值200U贡献值进入矩阵，并对应得到一个属于自己的矩阵，以此类推，当个人矩阵完成了6人次，此时将得到600U的奖励，系统将默认复投，每个矩阵重复循环，以此类推，您一次的贡献，将得到无限次的奖励。矩阵为一排二，二排四即为完成。',
      Desc2: '每个矩阵完成可随时提现奖励，提现时需要消耗25%的DMD币作为生态燃料。',
      Desc3: '全网上帮下扶式公排，在个人矩阵满员后，所直推的玩家在时间差上，随系统规则随机分布在网体中，也就是可能在其它玩家的矩阵中，以此类推，每个人的矩阵都是可以轻松完成。',
      Desc4: '每个玩家享有无限直推权限，无限直推10%，享永久会员复投收益，直推在网体任意位置，都会为玩家永久循环贡献奖励值。',
      // Desc5: '每个矩阵的完成贡献10U作为保险池，当系统识别7天未有新单进入时，系统自动重启，此时保险池自动分配给最后入场的500位玩家。',
      // Desc6: '重启时保留直推关系，原矩阵重排，按先后入场，公平竞争，先入先占位，让每个人都有机会成为更高排位点。',
      Desc5: ' 无一直推，出局奖励的余额可提50%，复投后推一个拿一轮，推两个拿两轮。推三个后终身有效，并享有5%的手续费加权分红。',
      // Desc8: '每轮未能提取的奖金按比例打50%直接进入DMD底池，20%奖励到卡牌，30%留下待提。',
      RecieveCardWay: '卡牌获取方式待定',
      IDOToTal: 'IDO总金额',
      AddressCount: '参与地址数量',
      IDOToTal: 'IDO总金额',
      CardRemain: '卡牌剩余量',
      MyCardCount: '卡牌拥有量',
      AWardTip: '恭喜您获得 {awardCard} 张卡牌',
      Community: '社区',
      MYCard: '我的卡牌',
      NocardTip: '未拥有卡牌',
      MyRecoomedCount: '个人直推',
      YZST1: 'DMD生态介绍',
      YZST2: 'DMD生态，是DMD赋能推出的一个生态板块，是一个生生不息，收益直观的矩阵模型，首创分布式直推循环收益，更具生命力。DMD生态是属于个人创业的一个模型，为每一个布道者，提供一个公平公正的创收平台。',
      YZST3: '出局次数',
      YZST4: '累计收益',
      YZST5: '直推数量',
      YZST6: '邀请码',
      YZST7: '加入生态，固定贡献值 100 USDT',
      YZST8: '我的团队',
      YZST9: '资金流水',
      YZST10: '提现',
      YZST11: '暂无团队',
      YZST12: '类型',
      YZST13: '金额',
      YZST14: '时间',
      YZST15: '暂无资金记录',
      YZST16: '可提现金额',
      YZST17: 'DMD余额',
      YZST18: '请输入提现金额',
      YZST19: '手续费',
      YZST20: '充值手续费',
      YZST21: '提现',
      YZST22: '推荐码',
      YZST23: '请输入推荐码',
      YZST24: '充值DMD',
      YZST25: '请输入充值金额',
      YZST26: 'DMD仅作为手续费使用',
      YZST27: '请填写推荐码',
      YZST28: '请输入DMD金额',
      YZST29: '充值成功',
      YZST30: '充值失败',
      YZST31: '请输入提现金额',
      YZST32: '买入',
      YZST33: '矩阵收益',
      YZST34: '直推收益',
      YZST35: '保险池',
      YZST36: '提现',
      YZST37: '手续费充值',
      YZST38: '手续费扣除',
      YZST39: '超出可提金额',
      YZST40: '提现手续费不足',
      YZST41: '保险池',
      YZST42: '投入',
      YZST43: '贡献值',
      YZST44: '成为影子生态的一员',
      YZST45: '开启属于你的财富之旅',
      YZST46: '您已成为DMD的一员',
      YZST47: '分享获取更多收益',
      YZST48: '复制邀请码',
      YZST49: '结算',
      YZST50: '冻结',
      YZST51: '冻结释放',
      YZST52: '认购额度',
      YZST53: '成为DMD会员',
      YZST54: '等待开放',
      YZST55: '我的资金',
      ShadowPlan: 'DMD',
      YZST56: '点击我',
      YZST57: '分享',
      YZST58: '好友',
      YZST59: '产矿',
      YZST60: '手续费返利',
      YZST61: '身份',
      YZST62: '编号',
      YZST63: '动能',
      YZST64: '我的奖金',
      YZST65: '地址',
      YZST66: '等级',
      YZST67: '直推人数',
      YZST68: '幸运池',
      YZST69: '当前幸运儿',
      YZST70: '下一个幸运儿',
      YZST71: '已参与',
      YZST72: '推荐奖金',
      YZST73: '平级奖金',
      YZST74: '幸运奖金',
    }
}