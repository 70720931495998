import axios from 'axios';
import Web3 from "web3";
import { JSEncrypt } from 'encryptlong'
import { b64tohex} from "jsencrypt/lib/lib/jsbn/base64";

axios.defaults.withCredentials = true;
const key = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC1cHrcMpXoKNC8rjAa/jAbgU2bhIBmJmn6iYDfqt0Him/p2s5F0L9nfzZOLYlPq3z12zvXl9IgThhtLIBLi86RPW0ljuwpTvIZz9O36Zae9eMk5bMNsFEFsxg1IOLIM2Oc4ffNvGL58Uupp9RL5NCN1MRQyY61ISy7H2VRTznoJQIDAQAB'
const privateKey = 'MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBANyCPnifddX7HYN/wexyqxt3cFCKoE1/+lCIv7i+Epo83xlwbRet6rTXyGX7JCs5qpg1UvC+LuB+Relv8bZPEwScLmu0B5awwDmY954974KD6Yn/JdHi2WT933QvmDs9PFqUfnQd0KhfAUF6eS2K0quwN9DOLX5UIcEZSE9SOwkdAgMBAAECgYAt52Mv4yo5qHQFavQoz9o09pFIAwPJ/mlSmJUo1VC0ERX0isX7FvZ0PTN3n2dxJCkSVI92lHDXxRGyvd4YsK9pdErGVL2DecbbER7069y94g/uHsYq15skzLlyUNhyu2FOUCsVFy+M/C0qk14z/xG1ze3GWNHP1oc1leAYpoWmAQJBAPXxRvYTxmZAFv3J4pcf/FXqmUXdbJI5sHWXe6Pcs+rIuil5ePqX6AQBoQ5PalYhe5/kKzr7Gy6GSzlpztuOV50CQQDlhrTNytQdDCNIOL8uw3t4D8mDGuslPcRoykmaCQhd4NvaqLZrBS8k+TuS2+v5ryng3HhcYjR0h1MHHG69En+BAkEAzt2G5mxzsIWv0QACi0LoKAwPp+g67jN/7SjhYk69Nnpnu4Lk80LacKQ+J588vf2j0jVE93NGxbdHhp8s0xfDMQJBAJFlOqVeRe28oaD/aYRLCqyse39Ujv6BWSj0c5li/WzSoxKpaxzyJMIsK2PFcn8xSbsKjigfgZELpDnN9gxJaoECQCn+tZB0g9WJMaGNCMdlStPHAO2ajS1HY6BV5Xq13hixAdp5bLzbg0GrY25MrpTCFBEqCx+7F4cYUvVazLuAK6g='

const service= axios.create({
    baseURL: 'http://154.91.195.148:8096/dapi',
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json;charset=utf-8'
    }
});
service.interceptors.request.use(config => {
    let web3 = new Web3(window.ethereum)
    let address = localStorage.getItem('address')
    let lang = localStorage.getItem('lang') 
    let key = web3.utils.sha3(localStorage.getItem('signData') + config.url + address)
    key = key ? key.substring(2) : ''
    address && (config.headers.address = address);
    key && (config.headers.key = key);
    config.headers.lang = lang ? (lang== 'en' ? 'en_us' : 'zh_cn') : 'en_us'
    
    if(config.data){ 
        config.data = setEncrypt(config.data)
    }
    return config; 
 }, error => {
     return Promise.reject(error);
 });

 service.interceptors.response.use(
    response => {
        if (response.status === 200) {
            let result = decryptLong(response.data);
            result = result ? JSON.parse(result) : {}
            if(result.code == 401) {
                localStorage.removeItem('address');
                localStorage.removeItem('chainType');
                localStorage.removeItem('signData');
            }
            return Promise.resolve(result);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        return Promise.reject(error);
    }
);

function setEncrypt (obj) {
    const jsencrypt = new JSEncrypt()
    const val = JSON.stringify(obj)
    jsencrypt.setPublicKey(key)
    return jsencrypt.encryptLong(val)
}

function decryptLong (val) {
    const enc = new JSEncrypt({});
    enc.setPrivateKey(privateKey);  // 私钥
    let k = enc.getKey();  
    let maxLength = 128;
    try {
      let hexStr = b64tohex(val);
      if (hexStr.length > maxLength * 2) {
        let hexStrArr = hexStr.match(/.{1,256}/g);  // 128位解密。取256位
        const ct = hexStrArr.map(entry => k.decrypt(entry)).join('');
        return ct;
      } else {
        return k.decrypt(hexStr);
      }
    } catch (ex) {
      return '';
    }
};
export default service;
