import {createRouter, createWebHashHistory} from 'vue-router';

const routes = [
    { path: '/', redirect: '/home' },
    { path: '/home', component: () => import('../pages/index/index') },
    { path: '/share', component: () => import('../pages/share') }
]
const router = createRouter({
    history: createWebHashHistory(),
    routes, 
})

export default router;
