import { createI18n } from 'vue-i18n' //引入vue-i18n组件
import en from './en'
import zh from './zh'
let language = '';
let langStorage = localStorage.getItem('lang')
if(langStorage) {
  language = langStorage;
} 
// else if(navigator.language) {
//   language = navigator.language.split("-")[0];
// } 
// else if (navigator.userLanguage) {
//   language = navigator.userLanguage.split("-")[0];
// } 
else {
  language = 'en'
}
const i18n = createI18n({
    globalInjection:true,
    legacy: false, 
    locale: language,
    messages: {
        en,
        zh
    },
});

export default i18n