export default {
    message: {
      ConnectWallet: 'Connect',
      ConnectTip: 'Please connect wallet',
      Mobile: 'Mobile',
      ChooseWallet: ' Choose your wallet',
      ChianTypeTip: 'Please switch to BSC Chain',
      Tips: 'Tips',
      LogOutTip: 'Are you sure to log out?',
      Ok: 'Ok',
      Cancel: 'Cancel',
      WaitTip: 'Please refresh the page or try again later!',
      BuySuc: 'Successful subscription',
      BuyErr: 'Subscription failure',
      Share: 'Share',
      Subscribe: 'Join now',
      Piece: 'Piece',
      Advantage: 'DMD advantage',
      AdvantageTip1: '1. Matrix rules, fairness and justice, intuitive benefits',
      AdvantageTip2: '2. One time investment, continuous income and long-term stability',
      AdvantageTip3: '3. Simplify the "multiple" exit system, without too many levels of algorithm reward routine',
      AdvantageTip4: '4. The pull-out rate of more than 95% is only for ecological empowerment',
      // AdvantageTip4: '4. Support the bottom safety pool, without the worry of restart',
      AdvantageTip5: '5. First distributed team circular revenue, long-term pipeline revenue',
      AdvantageTip6: '6. Help from top to bottom, fill from left to right, and accelerate revenue',
      Partner: 'Partner',
      CopySuc: 'Copy successful',
      CopyErr: 'Copy failed',
      MatrixDesc: 'Matrix desc',
      ShareAward: 'Invite new users to get great rewards',
      ShareLink: 'Your exclusive invitation link',
      CopyLink: 'Copy',
      NoBlind: 'Uncompleted mission value of opening blind box',
      Desc1: 'each participating player recharges 200u contribution value into the matrix and gets a corresponding matrix. By analogy, when the individual matrix completes 6 times, you will get 600u reward. The system will re cast, and each matrix repeats the cycle. By analogy, you will get unlimited rewards for one contribution. The matrix is two in one row and four in two rows is complete. ',
      Desc2: 'each matrix can be withdrawn at any time, and 25% of DMD coins need to be consumed as ecological fuel.',
      Desc3: "The whole online help down public volleyball. When the personal matrix is full, the players directly pushed are randomly distributed in the network body according to the system rules in terms of time difference, that is, they may be in the matrix of other players, and so on. Everyone's matrix can be easily completed",
      Desc4: 'Each player has unlimited direct push permission, 10% of which is unlimited direct push, and benefits from the return of permanent members. Direct push at any position of the network will contribute reward value to players in a permanent cycle.',
      // Desc5: 'the completion contribution of each matrix is 10u as a safe pool. When the system recognizes that no new order has entered within 7 days, the system will automatically restart. At this time, the safe pool will be automatically allocated to the last 500 players.',
      // Desc6: 'during restart, the direct push relationship is maintained, the original matrix is rearranged, and admission is conducted according to order, fair competition, first in first place, so that everyone has a chance to become a higher ranking point.',
      Desc5: 'If there is no constant push, the balance of the exit reward can be increased by 50%. After the second round, one will get one round, and two will get two rounds. After three referrals, it will be valid for life, and will enjoy a 5% service charge weighted dividend.',
      // Desc8: '50% of the bonus that cannot be withdrawn in each round will be directly entered into the DMD bottom pool, 20% will be awarded to cards, and 30% will be left to be withdrawn.',
      RecieveCardWay: 'Card acquisition method to be determined',
      AddressCount: 'Number of participating addresses',
      IDOToTal: 'Total amount of IDO',
      CardRemain: 'Card remaining',
      MyCardCount: 'Card ownership',
      AWardTip: 'Congratulations on getting {awardCard} cards',
      Community: 'Community',
      MYCard: 'My cards',
      NocardTip: "You don't have cards yet",
      MyRecoomedCount: 'Direct push number',
      YZST1: 'Introduction',
      YZST2: 'DMD Ecology is an ecological sector launched by DMD enabling. It is a matrix model with continuous growth and intuitive income. It is the first to create distributed direct circular income, which has more vitality. DMD ecology is a model of personal entrepreneurship, which provides a fair and just income generating platform for every preacher.',
      YZST3: 'Outgoing times',
      YZST4: 'Cumulative income',
      YZST5: 'Direct push quantity',
      YZST6: 'Invitation code',
      YZST7: 'Join ecology, fixed contribution value 100 usdt',
      YZST8: 'My team',
      YZST9: 'Fund flow',
      YZST10: 'Withdrawal',
      YZST11: 'No team temporarily',
      YZST12: 'Type',
      YZST13: 'Amount',
      YZST14: 'Time',
      YZST15: 'No fund record temporarily',
      YZST16: 'Withdrawable amount',
      YZST17: 'DMD balance',
      YZST18: 'Please enter the withdrawal amount',
      YZST19: 'Handling fee',
      YZST20: 'Recharge',
      YZST21: 'Withdrawal',
      YZST22: 'Recommendation code',
      YZST23: 'Please enter the recommendation code',
      YZST24: 'Recharge DMD',
      YZST25: 'Please enter the recharge amount',
      YZST26: 'DMD is only used as a service fee and cannot be withdrawn',
      YZST27: 'Please fill in the recommendation code',
      YZST28: 'Please enter DMD amount',
      YZST29: 'Recharge succeeded',
      YZST30: 'Recharge failed',
      YZST31: 'Please enter the withdrawal amount',
      YZST32: 'Buy',
      YZST33: 'Matrix income',
      YZST34: 'Direct push revenue',
      YZST35: 'Safe pool',
      YZST36: 'Withdrawal',
      YZST37: 'Service charge recharge',
      YZST38: 'Service charge deduction',
      YZST39: 'Excess of withdrawable amount',
      YZST40: 'Insufficient withdrawal service fee',
      YZST41: 'Safe pool',
      YZST42: 'Input',
      YZST43: '',
      YZST44: 'Become a member of the shadow ecology',
      YZST45: 'Start your journey of wealth',
      YZST46: 'You have become a member of the shadow ecology',
      YZST47: 'Share get more benefits',
      YZST48: 'Copy',
      YZST49: 'Settlement',
      YZST50: 'Frozen Amount',
      YZST51: 'Release Freezen',
      YZST52: 'Subscription Limit',
      YZST53: 'Become a member of DMD',
      YZST54: 'Waiting Opening',
      YZST55: 'My Funds',
      ShadowPlan: 'DMD',
      YZST56: 'Click Me',
      YZST57: 'Share',
      YZST58: 'Friends',
      YZST59: 'Production',
      YZST60: 'Fee',
      YZST61: 'Identity',
      YZST62: 'Number',
      YZST63: 'Kinetic energy',
      YZST64: 'My bonus',
      YZST65: 'Address',
      YZST66: 'Grade',
      YZST67: 'Direct push',
      YZST68: 'Lucky Pool',
      YZST69: 'Current lucky boy',
      YZST70: 'Next lucky boy',
      YZST71: 'Participated',
      YZST72: 'Referral Bonus',
      YZST73: 'Peer Bonus',
      YZST74: 'Lucky Bonus',
    }
}