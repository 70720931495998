import { createApp } from 'vue'
import { Button, Overlay, Toast, Notify, Dialog, Swipe, SwipeItem   } from 'vant';
import App from './App.vue'

import 'lib-flexible/flexible'
import './assets/style/common.css'
import i18n from './i18n'
import axios from './js/axios';
import router from './router/index';

const app = createApp(App);
app.use(i18n);

app.use(Button);
app.use(Overlay);
app.use(Toast);
app.use(Notify);
app.use(Dialog);
app.use(Swipe);
app.use(SwipeItem);
app.use(router);


app.config.globalProperties.$axios = axios;

// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()

app.mount('#app')
