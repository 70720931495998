<template>
  <keep-alive>
    <router-view></router-view>
  </keep-alive>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style >
@import './assets/fonts/font';
#app {
  font-family: ui-rounded,SF Pro Rounded,SF Pro Text,medium-content-sans-serif-font,-apple-system,BlinkMacSystemFont,ui-sans-serif,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-family: 'HarmonyOS_Sans_SC_Regular', '微软雅黑';
  font-size: 24px;
  color: #fff;
  width: 100%;
  overflow-x: hidden;
}
*{   
    -webkit-touch-callout:none;  /*系统默认菜单被禁用*/   
    -webkit-user-select:none; /*webkit浏览器*/   
    -khtml-user-select:none; /*早期浏览器*/   
    -moz-user-select:none;/*火狐*/   
    -ms-user-select:none; /*IE10*/   
    user-select:none;   
}
input {      
    user-select: auto;
     -webkit-user-select:auto; /*webkit浏览器*/     
}
</style>
